<template>
  <div>
    <ViewTemplateReportPreview :panel="panel" :templateList="templateList" :templateReport="templateReport" />

    <Modal title="Despesa" :width="1100" :height="550" v-if="showModal('expense')">
      <Loading :center="false" v-show="loading" />
      <Report v-show="!loading" :titleHeader="subReport.title" :minHeight="500" :header="subReport.fields"
        :data="subReport.data" :totalizationCustom="subReport.totalization" />
    </Modal>
  </div>
</template>

<script>
import ViewTemplateReportPreview from "@nixweb/nixloc-ui/src/component/template/ViewTemplateReportPreview.vue";
import Report from "@nixweb/nixloc-ui/src/component/shared/Report.vue";
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";
import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading.vue";

import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  name: "ReportProductView",
  components: {
    ViewTemplateReportPreview, Modal, Report, Loading
  },
  data() {
    return {
      panel: {
        module: "Locação",
        title: "Receita x Despesa de Locação",
        showFilter: false,
        showSearch: false,
        showButtons: false,
      },
      templateList: {
        urlGetApi: "/odata/RentPerformanceReportOData",
        urlGetSubReportApi: "/api/v1/finance/relationship-rent/report-by-rent",
        showChecks: false,
      },
      templateReport: {
        fields: [
          {
            field: "registeredDate",
            title: "Dt Cadastro",
            type: "date",
            orderBy: "asc",
            selected: true,
            requiredOData: false,
          },
          {
            field: "dateAccepted",
            title: "Dt Efetivação",
            type: "date",
            selected: false,
            requiredOData: false,
          },
          {
            field: "typeName",
            title: "Tipo",
            type: "text",
            selected: false,
            requiredOData: false,
          },
          {
            field: "number",
            title: "Número",
            type: "text",
            selected: true,
            requiredOData: true,
            classCssTitle: "text-center",
            classCssBody: "text-center",
          },
          {
            field: "identification",
            title: "Identificação",
            type: "text",
            selected: true,
            requiredOData: false,
          },
          {
            field: "collaboratorName",
            title: "Consultor",
            type: "text",
            selected: false,
            requiredOData: false,
            styleHeader: "width:150px;",
            styleBody: "max-width: 150px; white-space: nowrap;",
          },
          {
            field: "companyName",
            title: "Razão Social/Nome",
            type: "text",
            selected: true,
            requiredOData: false,
            styleHeader: "width:250px;",
            styleBody: "max-width: 250px; white-space: nowrap;",
          },
          {
            field: "tradeName",
            title: "Fantasia/Apelido",
            type: "text",
            selected: false,
            requiredOData: false,
            styleHeader: "width:150px;",
            styleBody: "max-width: 150px; white-space: nowrap;",
          },
          {
            field: "contactName",
            title: "Contato",
            type: "text",
            selected: false,
            requiredOData: false,
            styleHeader: "width:120px;",
            styleBody: "max-width: 120px; white-space: nowrap;",
          },
          {
            field: "statusRentalName",
            title: "St Locação",
            type: "text",
            selected: false,
            requiredOData: false,
            fieldComparison: "statusRentalName",
            classCssTitle: "text-center",
            classCssBody: "text-center",
            styleHeader: "width:130px;",
            styleBody: "max-width: 130px; white-space: nowrap;",
          },
          {
            field: "revenue",
            title: "Receita",
            type: "currency",
            selected: true,
            requiredOData: false,
            styleHeader: "max-width:150px;",
            styleBody: "max-width: 150px; white-space: nowrap;",
          },
          {
            field: "expense",
            title: "Despesa",
            type: "currency",
            eventName: "expense",
            selected: true,
            requiredOData: false,
            styleHeader: "max-width:150px;",
            styleBody: "max-width: 150px; white-space: nowrap;cursor:pointer;text-decoration: underline;",
          },
          {
            field: "margin",
            title: "Margem R$",
            type: "currency",
            selected: true,
            requiredOData: false,
            styleHeader: "max-width:150px;",
            styleBody: "max-width: 150px; white-space: nowrap;",
          },
          {
            field: "percentage",
            title: "Margem %",
            type: "text",
            selected: true,
            requiredOData: false,
            classCssTitle: "text-center",
            classCssBody: "text-center",
            styleHeader: "max-width:150px;",
            styleBody: "max-width: 150px; white-space: nowrap;",
          },
        ],
        rules: [
          {
            name: "registeredDate",
            title: "Período",
            icon: "fas fa-database",
            typeField: "dateTime",
            type: "field",
            props: {
              format: "DD/MM/YYYY",
              type: "date",
              range: true,
              filterByField: [
                { text: "Data de Cadastro", value: "registeredDate" },
                { text: "Data de Efetivação", value: "dateAccepted" },
              ],
            },
            notDuplicate: true,
            isRequired: true,
          },
          {
            name: "number",
            title: "Número",
            icon: "fas fa-database",
            typeField: "numeric",
            type: "field",
            notDuplicate: false,
            isRequired: false,
          },
          {
            name: "identification",
            title: "Identificação",
            icon: "fas fa-database",
            typeField: "text",
            type: "field",
            notDuplicate: false,
            isRequired: false,
          },
          {
            name: "collaboratorId",
            title: "Consultor",
            icon: "fas fa-database",
            typeField: "select",
            type: "field",
            props: {
              url: "/api/v1/human-resources/collaborator/select-all",
            },
            notDuplicate: false,
            isRequired: false,
          },
          {
            name: "customerSupplierId",
            title: "Cliente",
            icon: "fas fa-database",
            typeField: "select",
            type: "field",
            props: {
              url: "/api/v1/crm/customer/select-all",
            },
            notDuplicate: false,
            isRequired: false,
          },
          {
            name: "revenue",
            title: "Receita",
            icon: "fas fa-database",
            typeField: "numeric",
            type: "field",
            notDuplicate: false,
            isRequired: false,
          },
          {
            name: "expense",
            title: "Despesa",
            icon: "fas fa-database",
            typeField: "numeric",
            type: "field",
            notDuplicate: false,
            isRequired: false,
          },
          {
            name: ["revenue", "expense"],
            title: "Totalização",
            icon: "fas fa-sigma",
            typeField: "checkbox",
            classCss: "primary",
            type: "totalization",
            options: [
              {
                title: "Total Receita",
                value: "sum_Revenue",
                aggregate: {
                  title: "Total Receita",
                  value: "sumRevenue",
                  type: "currency",
                },
              },
              {
                title: "Total Despesa",
                value: "sum_Expense",
                aggregate: {
                  title: "Total Despesa",
                  value: "sumExpense",
                  type: "currency",
                },
              },
            ],
            notDuplicate: true,
            isRequired: false,
          },
        ],
      },
      subReport: {
        title: "",
        fields: [
          {
            field: "recipientName",
            title: "Destinatário",
            type: "text",
            styleHeader: "width:250px;",
            styleBody: "max-width: 250px; white-space: nowrap;",
          },
          {
            field: "description",
            title: "Descrição",
            type: "text",
            styleHeader: "width:250px;",
            styleBody: "max-width: 250px; white-space: nowrap;",
          },
          {
            field: "dueDate",
            title: "Dt Vencimento",
            type: "date",
          },
          {
            field: "general",
            title: "Valor",
            type: "currency",
          },
        ],
        data: [],
        totalization: [],
      },
      loading: false,
    };
  },
  computed: {
    ...mapGetters("generic", ["event", "showModal"]),
  },
  methods: {
    ...mapActions("generic", ["getApi"]),
    ...mapMutations("generic", ["openModal"]),
    getAll(number) {
      this.loading = true;
      let params = {
        url: this.templateList.urlGetSubReportApi,
        obj: { id: number },
      };
      this.getApi(params).then((response) => {
        console.log(response);
        this.subReport.data = response.content.data;
        this.subReport.totalization = response.content.totalization;
        this.loading = false;
      });
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "expense") {
          this.subReport.title = `Locação Nº ${event.data.number} - ${event.data.companyName}`;
          this.getAll(event.data.number);
          this.openModal("expense");
        }
      },
      deep: true,
    },
  },
};
</script>
